$alpha: rgb(62, 189, 125);
$beta: rgb(110, 215, 151);
$gamma: rgb(250, 175, 31);
$delta: rgb(247, 108, 34);
$epsilon: rgb(215, 36, 100);

// Colors:
$background: lighten($alpha, 40%);
$box-background: lighten($beta, 30%);
$primary-text: $epsilon;
$egdes: $gamma;

$main-font: 'Courier New', Courier, monospace;

html,
body {
  background-color: $background;
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

.content {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
  padding: 16px;
  background-color: $box-background;

  div {
    text-align: center;
    max-width: 960px;
    color: $primary-text;
    font-family: $main-font;
    margin: 0 auto;
    font-size: 12px;
  }

  a {
    color: $primary-text;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  margin-right: auto;
  margin-left: auto;
  min-width: 350px;
  max-width: 960px;
  padding: 0 20px;
}

.title {
  color: $primary-text;
  font-family: $main-font;
  font-size: 32pt;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;

  @media (min-width: 600px) {
    font-size: 42pt;
  }

  &.hide {
    display: none;
  }
}

.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  height: 550px;
  border-radius: 10px;
  background-color: $box-background;
  outline: 2px dashed darken($box-background, 60%);
  outline-offset: -10px;

  &.is-dragover {
    background-color: darken($box-background, 10%);
  }

  &.has-results {
    transition: height 0.5s;
    height: 100px;

    .instruction {
      &::after {
        visibility: hidden;
      }
      margin-top: 0;
      font-size: 20pt;
    }
  }


  .instruction {
    position: relative;
    margin-top: 2em;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      display: inline-block;
      margin-left: -75px;
      width: 150px;
      height: 150px;
      mask: url('./images/caret-square-down-solid.svg') no-repeat 50% 50%;
      mask-size: auto;
      -webkit-mask: url('./images/caret-square-down-solid.svg') no-repeat 50% 50%;
      -webkit-mask-size: auto;
      background-color: lighten($primary-text, 20%);
    }
    color: $primary-text;
    font-family: $main-font;
    font-size: 32pt;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 600px) {
      font-size: 42pt;
    }
  }

  .uploading,
  .success,
  .error {
    display: none;
  }
}

.result-box {
  display: none;
  &.has-results {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pointer {
    width: 60px;
    height: 60px;
    mask: url('./images/angle-double-down-solid.svg') no-repeat 50% 50%;
    mask-size: auto;
    -webkit-mask: url('./images/angle-double-down-solid.svg') no-repeat 50% 50%;
    -webkit-mask-size: auto;
    background-color: lighten($delta, 20%);
  }
  .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;

    .checkers {
      position: relative;
      background-image: /* tint image */ linear-gradient(
          to right,
          rgba(255, 255, 255, 0.85),
          rgba(255, 255, 255, 0.85)
        ),
        /* checkered effect */ linear-gradient(to right, black 50%, white 50%),
        linear-gradient(to bottom, black 50%, white 50%);
      background-blend-mode: normal, difference, normal;
      background-size: 2em 2em;
      box-shadow:         inset 0 0 5px #000000;
      border-radius: 15px;
      margin-bottom: 24px;
      width: 100%;
      text-align: center;

      img {
        max-width: 920px;
      }
    }

    .action {
      cursor: pointer;

      &.download {
        &.clicked {
          background-color: lighten($primary-text, 30%);
        }
        &:hover {
          background-color: lighten($primary-text, 10%);
        }

        position: absolute;
        right: 15px;
        bottom: 5px;
        display: inline-block;
        content: '';
        width: 60px;
        height: 60px;

        mask: url('./images/cloud-download-alt-solid.svg') no-repeat 50% 50%;
        mask-size: auto;
        -webkit-mask: url('./images/cloud-download-alt-solid.svg') no-repeat 50% 50%;
        -webkit-mask-size: auto;
        background-color: lighten($primary-text, 20%);
      }
    }
  }
}
